// 运营模板
<template>
  <!-- 页面name：运营任务 -->
  <div class="dict">
        <!--  v-if="isAdd" -->
    <div class="form-header-btn btn1" >
      <div class="title">运营模板</div>
      <div>
        <el-button type="primary" v-if="isAdd" @click="handleAdd" 
          >新增</el-button
        >
      </div>
    </div>
    <div class="form-header-btn btn2">
      <div>
        <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
          <el-form-item>
            <el-input v-model="form.keyword" placeholder="模板名称" />
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.is_use" placeholder="请选择状态">
                <el-option
                v-for="item in form.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button @click="resetFun" icon="el-icon-refresh-right" class="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.60417vw)'"
      :autoHeight="false"
    >
      <el-table-column
        prop="templateName"
        label="模板名称"
        align="center"
        min-width="150"
        slot="templateName"
      ></el-table-column>
      <el-table-column
        prop="totalItem"
        min-width="160"
        label="项目数量"
        align="center"
        slot="totalItem"
      ></el-table-column>
      <el-table-column
        prop="totalPredictDay"
        label="总计时长"
        align="center"
        min-width="150"
        slot="totalPredictDay"
      ></el-table-column>
      <el-table-column
        prop="templateSort"
        min-width="160"
        label="排序"
        align="center"
        slot="templateSort"
      ></el-table-column>
      <el-table-column
        prop="isUse"
        label="状态"
        min-width="150"
        align="center"
        slot="isUse"
      >
        <template  slot-scope="scope" >
            <!-- <span v-if="scope.row.isUse">{{scope.row.isUse.value}}</span> -->
          <span v-if="scope.row.isUse">
            <el-switch :width="30" disabled  :value="scope.row.isUse.value" active-value="1"  inactive-value="0">
          </el-switch>
          {{scope.row.isUse.label}}
        </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="templateDesc"
        slot="templateDesc"
        label="描述"
        align="center"
        min-width="150"
      >
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.templateDesc" placement="top">
            <span class="templateDesc">{{scope.row.templateDesc}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="handle" align="center" fixed="right" min-width="250">
        <template slot-scope="scope">
            <!-- v-if="isUpdate" -->
          <el-button type="text" @click="release(scope.row)" v-if="isDeploy">发布</el-button>
          <el-button type="text" @click="handleEdit(scope.row,2)" v-if="isCopy">复制</el-button>
          <el-button type="text" @click="goDetail(scope.row)" v-if="isView">查看</el-button>
          <el-button size="small" type="text" @click="handleEdit(scope.row,1)" v-if="isUpdate"
            >编辑</el-button
          >
          <el-popconfirm
            :icon="'el-icon-error'"
            :icon-color="'#C6000B'"
            title="你确定要删除此项目吗？" @confirm="handelDel(scope.row)"
          >
          <!--  @click="handelDel(scope.row)" -->
            <el-button slot="reference" v-if="isDelete"  class="textBtn" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </l-table>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    return {
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
        pageSizes: [10, 20, 50, 100, 500],
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "模板名称",
          slotName: "templateName",
        },
        {
          label: "项目数量",
          slotName: "totalItem",
        },
        {
          label: "总计时长",
          slotName: "totalPredictDay",
        },
        {
          label: "排序",
          slotName: "templateSort",
        },
        {
          label: "状态",
          slotName: "isUse",
        },
        {
          label: "描述",
          slotName: "templateDesc",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      form:{
        keyword:'',
        is_use:'',
        options:[
            {value:'1',label:'启用'},
            {value:'0',label:'不启用'},
        ]
      },
      current: 1,
      currentIndex: 1,
      pageSize: 10,
      tableData: [],
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      isDeploy:false,
      isCopy:false,
      isView:false,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
    showNum() {
      let start = (this.current - 1) * this.ipagination.size + 1;
      let end =
        this.current * this.ipagination.size > this.ipagination.total
          ? this.ipagination.total
          : this.current * this.ipagination.size;
      return this.ipagination.total === 0
        ? this.ipagination.total
        : `${ start }-${ end }`;
    },
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("operationTemplate:edit") != -1;  //编辑
          this.isAdd = val.indexOf("operationTemplate:add") != -1;  //添加
          this.isDelete = val.indexOf("operationTemplate:del") != -1; //删除
          this.isDeploy = val.indexOf("operationTemplate:deploy") != -1; //发布
          this.isCopy = val.indexOf("operationTemplate:copy") != -1; //复制
          this.isView = val.indexOf("operationTemplate:view") != -1; //删除
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.init();
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      if (this.form.keyword == "") {
        this.form.keyword = null;
      }
      // this.page.num = 1;
      this.tableLoading = true;
      this.$api
        .getWorkTemplate(
          Object.assign({
            current: this.page.num,
            size: this.page.size,
            templateName: this.form.keyword,
            isUse:this.form.is_use
          })
        )
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    // 添加一级二级和三级
    handleAdd(){
        this.$router.push({
          path:'/editOperatingTemplate',
          query:{
            isEdit:0,
          }
        })
    },
    handleEdit(row,type){
      if(type == 1){
        this.$router.push({
          path:'/editOperatingTemplate',
          query:{
            isEdit:1,
            id:row.id
          }
        })
      }else  if(type == 2){
        this.$router.push({
          path:'/editOperatingTemplate',
          query:{
            isEdit:2,
            id:row.id
          }
        })
      }
    },
    // 发布
    release(row){
      // console.log(row)
      this.$router.push({
        path:'/editOperatingTask',
        // editOperatingTask?isEdit=0
        query:{
          isEdit:0,
          id:row.id
        }
      })
      // this.$api.taskListPublish(row.id).then((res)=>{
      //   if(res.data.code == '0'){
      //     this.$$message.success('发布成功')
      //     this.page.num = 1
      //     this.init()
      //   }else{
      //     this.$message.error(res.data.msg)
      //   }
      // })
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.keyword = "";
      this.form.is_use = "";
      this.page.num = 1;
      this.init();
    },
    /** 行内-删除 */
    handelDel(row) {
        this.$api.workTemplateDel(row.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            // this.page.num = 1;
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    goDetail(row){
      this.$router.push({
        path:'/detailOperatingTemplate',
        query:{
          id:row.id
        }
      })
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    height: calc(100% - 106px);
    position: relative;
  }
}
.createBtn{
  position: absolute;
  top: 50%;
  z-index: 50;
  // left: 20%;
  width: 90%;
  text-align: center;
  background-color: #fff;
  height: 80px;
}
.form-header-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  .sheet-btn {
    background: #2a00b2;
    box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
  }

  /deep/.el-button + .el-button {
    margin-left: 20px;
  }

  &.btn2{
    margin-bottom: 0px;
  }
  &.btn1{
    margin-bottom: 16px;
  }
}
.title{
  font-weight: bold;
  font-size: 22px;
}
.l-pages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  .total-num {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #7c7c7c;
  }
  .el-pagination {
    padding: 0;
    .el-pagination__sizes {
      margin: 0 0 0 8px;
    }
  }
  .el-pagination .el-select .el-input {
    .el-input__icon {
      right: 20px;
    }
    input {
      width: 100px;
      height: 32px;
      border: 1px solid #e8ecef;
      opacity: 1;
      border-radius: 4px;
      padding: 3px 10px !important;
    }
  }
  .btn-prev,
  .btn-next,
  .el-pager li {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid #e8ecef !important;
  }
  .btn-next {
    margin-left: 8px;
  }
  .el-pager li {
    line-height: 30px;
    border-radius: 6px;
    font-size: 14px;
    color: #6a6d77;
    margin-left: 8px;
    &:hover {
      background-color: #004ea2 !important;
      border: 1px solid #004ea2 !important;
      color: #ffffff;
    }
  }
  .el-pager li.active {
    background-color: #004ea2 !important;
    border: 1px solid #004ea2 !important;
    color: #ffffff;
  }
}
.templateDesc{
  width: 100%;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.textBtn{
  margin-left: 10px;
}
</style>
